import React from 'react';
import { PageTitle } from '../../config/config';
const Footer = () => {
  return (
    <div className='flex justify-between container mx-auto pt-24'>
      <p style={{color: 'var(--text-header-color)'}} className='text-[12px] text-center'>
      Disclaimer: {PageTitle} is not affiliated with Weidian.com, Taobao.com, 1688.com, tmall.com or any other shopping site ("platforms"). This website is not an official offer of those platforms.
      All shopping agent links, namely pandabuy.com / wegobuy.com / superbuy.com / hagobuy.com / sugargoo.com / cssbuy.com  / cnfans.com
      are affiliate links for agents. We do not get a commission for the sale of the item, only for their function as a freight forwarder.
      All information disclosed on this page is disclosed "as is" and without any representation, warranty, implied or otherwise, regarding its accuracy or performance and, in particular, with respect to the non-infringement of trademarks, patents, copyrights or any other intellectual property rights, or any other rights of third parties.
      <br/> <span>&#169;</span>{PageTitle}
      </p>
    </div>
  );
};

export default Footer;

import React from 'react'
import { AgentName } from '../../config/config'
const hero = () => {
  return (
    
  <div className='container mx-auto mt-20 max-sm:mt-14'>
    <div className='heroHeaders max-lg:pl-9'>
      <h1 className='heroTxt max-sm:text-4xl'>Explore the 2024 Web-based {AgentName} Spreadsheet</h1>
      <h1 className='heroSubheader max-sm:text-xl'>Discover a selection of over 3500 unique fashion reps on {AgentName} with QC links.</h1>
      <h1 className='heroSubheader max-sm:text-xl'>Effortlessly filter products by Sales, Price, and Alphabetical order.</h1>
      <h1 className='heroSubheader max-sm:text-xl'>Save your favorite items to the watchlist for quick access, easy comparison & total prices</h1>
      <div className='pt-4'>
      <a href="/products" className='btn6 text-xl'>Browse Products</a>
      </div>
      
    </div>
  </div>
  )
}

export default hero
